






































































































































import { defineComponent, ref } from "@vue/composition-api";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { mapActions, mapGetters } from "vuex";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { IArchiveCardElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveCardElement";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/archiveView/viewTitle";
import {
  baseModalElements,
  fullModalElements as modalElements,
  techElements,
} from "@monorepo/utils/src/variables/projectsData/archiveView/modalElements";
import { convertStatus } from "@monorepo/catalog/src/views/ArchiveView/utils/convertApiItemToUi";
import { convertSaveCardObject } from "@monorepo/catalog/src/views/ArchiveView/utils/convertSaveCardObject";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { fields, IArchiveFields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import eventBus from "@monorepo/utils/src/eventBus/index";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { Route } from "vue-router";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import DeleteBtn from "@monorepo/uikit/src/components/tableViews/DeleteBtn.vue";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import FundsTable from "@monorepo/catalog/src/views/ArchiveView/components/FundsTable.vue";

export default defineComponent({
  name: "ArchiveInfoModal",
  components: {
    CardModalInfoElement,
    FormInputElement,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    SelectRolesCard,
    ExportBtn,
    DeleteBtn,
    CardModalChapter,
    FundsTable,
  },
  props: {
    type: {
      type: String,
      default: ModalType.NEW,
    },
  },
  data(): {
    ModalType: typeof ModalType;
    key: string;
    isSaveLoading: boolean;
    viewUniqKey: string;
    modalElements: IModalElementType[];
    baseModalElements: IModalElementType[];
    techElements: IModalElementType[];
    openedPanels: number[];
    fields: IArchiveFields;
    isOpenByTemplate: boolean;
    isLoading: boolean;
    cardMode: typeof CardMode;
    section: Sections;
  } {
    return {
      ModalType,
      isSaveLoading: false,
      key: uuid(),
      viewUniqKey,
      isOpenByTemplate: false,
      modalElements,
      baseModalElements,
      techElements,
      cardMode: CardMode,
      section: Sections.ARCHIVE,
      openedPanels: [0, 1],
      fields,
      isLoading: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          isOpenByTemplate: boolean;
          changedFormValues: Record<string, unknown>;
          getResultSaveData: (data: Partial<IArchiveCardElement>) => Partial<IArchiveCardElement>;
          $route: Route;
          refreshData: (archiveId: string) => void;
        },
        value
      ) {
        if (this.isOpenByTemplate) {
          // Открытие по шаблону
          this.isOpenByTemplate = false;
          return;
        }

        if (value) {
          await this.addQueryOpenedId();
          await this.refreshData(value);
        } else {
          this.changedFormValues = this.getResultSaveData({});
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("archiveCatalogView", ["data", "openedId", "fundsLib"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_EXPORT);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_MODIFY);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_DELETE);
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    statusColor(): string {
      const status: string = (this.formValues as IArchiveCardElement)[fields.ARCHIVE_STATUS as keyof IArchiveCardElement] as string;
      switch (status) {
        case "Активный":
          return "#00A459";
        case "Удален":
        case "Объединен":
          return "#D34039";
        default:
          return "";
      }
    },
    title(): string {
      switch (this.type) {
        case ModalType.EDIT:
        case ModalType.READ:
          return (this.formValues as IArchiveCardElement).name as string;
        case ModalType.NEW:
        default:
          return "Новый архив";
      }
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("archiveCatalogView", ["changeOpenedId", "addOpenedId"]),
    ...mapActions("archiveCatalogView", ["getCardElement", "saveCard", "refreshScroll", "deleteElement", "restoreEntity"]),
    refreshData(archiveId: string) {
      this.isLoading = true;
      this.getCardElement(archiveId)
        .then((data: IArchiveCardElement) => {
          this.$emit(
            "changeType",
            !data.isEditable
              ? ModalType.READ
              : this.user.authorities?.find((element: { authority: string }) => {
                  return element.authority === authorities.ARCHIVE_MODIFY;
                }) && !data.isDeleted
              ? ModalType.EDIT
              : ModalType.READ
          );

          this.formValues = {
            ...data,
            [fields.ARCHIVE_STATUS]: convertStatus(!!data[fields.ARCHIVE_IS_DELETED as keyof IArchiveCardElement]),
            [fields.ARCHIVE_CREATION_DATE]: data[fields.ARCHIVE_CREATION_DATE as keyof IArchiveCardElement]
              ? moment((data[fields.ARCHIVE_CREATION_DATE as keyof IArchiveCardElement] as string).toString()).format("YYYY-MM-DD HH:mm")
              : "",
            [fields.ARCHIVE_UPDATE_DATE]: data[fields.ARCHIVE_UPDATE_DATE as keyof IArchiveCardElement]
              ? moment((data[fields.ARCHIVE_UPDATE_DATE as keyof IArchiveCardElement] as string).toString()).format("YYYY-MM-DD HH:mm")
              : "",
            [fields.ARCHIVE_FUND]: (data.funds as { shortName: string }[]).map((item) => ({
              ...item,
              label: item.shortName,
            })),
          };
          this.changedFormValues = this.getResultSaveData(this.formValues);
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    openNewModal() {
      this.$emit("changeType", ModalType.NEW);
      this.$router.replace({ query: {} });
      const resultData = this.getResultSaveData(this.formValues);
      delete resultData.id;
      delete resultData.code;
      this.formValues = resultData;
      this.key = uuid();
      this.isOpenByTemplate = true;
    },
    openDeleteModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.deleteElementCb,
          title: `Удалить архив «${(this.formValues as IArchiveCardElement).name}»?`,
        })
      );
    },
    openRestoreModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.restoreOikCb,
          yesBtnTitle: "Да, восстановить",
          icon: "mdi-delete-restore",
          title: `Восстановить архив «${(this.formValues as IArchiveCardElement).name}»?`,
        })
      );
    },
    async restoreOikCb() {
      const isDeleted = await this.restoreEntity((this.formValues as IArchiveCardElement).id);
      if (isDeleted) {
        this.refreshScroll();
        this.closeModal();
      } else {
        showNotification("Ошибка восстановления архива");
      }
    },
    openExport() {
      this.openNewWindow(getFullPath(QUERY_PATH.GET_CATALOG_ARCHIVE_PDF, "/", (this.formValues as IArchiveCardElement).id as string));
    },
    async deleteElementCb() {
      const isDeleted = await this.deleteElement({ ids: [(this.formValues as IArchiveCardElement).id] });
      if (isDeleted) {
        this.refreshScroll();
        this.closeModal();
      } else {
        showNotification("Ошибка удаления архива");
      }
    },
    getResultSaveData(data: Partial<IArchiveCardElement>) {
      const resultData: Partial<IArchiveCardElement> = {
        id: data.id || (this.formValues as IArchiveCardElement).id,
        ...data,
      };
      return convertSaveCardObject(resultData);
    },
    async onSave(data: Record<string, string | boolean>) {
      const isNeedSave = (this as unknown as { checkChangesBeforeSave: () => boolean }).checkChangesBeforeSave();
      if (isNeedSave) {
        const resultData = this.getResultSaveData(data);

        this.isSaveLoading = true;
        const id = await this.saveCard(resultData).finally(() => {
          this.isSaveLoading = false;
        });

        if (id) {
          this.changedFormValues = resultData;
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
          this.refreshScroll();
          if (!this.openedId) {
            this.addOpenedId(id);
            this.$emit("changeType", ModalType.EDIT);
          } else {
            this.refreshData(this.openedId);
          }
        }
      } else {
        return;
      }
    },
    changeContracts(
      this: {
        $formulate: {
          setValues: (key: string, object: Record<string, string>) => void;
        };
      },
      contracts: Record<string, string>[],
      values: Record<string, string>
    ) {
      this.$formulate.setValues("oik-info-modal", Object.assign(values, { contracts }));
    },
    closeModal(): void {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({});
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "archiveCatalogView", Sections.ARCHIVE, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "archiveCatalogView");

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
    };
  },
});
