
































































































import { Route } from "vue-router";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import AddElement from "@monorepo/uikit/src/components/tableViews/AddElement.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import ArchiveInfoModal from "@monorepo/catalog/src/views/ArchiveView/components/ArchiveInfoModal.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/archiveView/tableHeaders";
import { defineComponent, ref } from "@vue/composition-api";
import { module as ArchiveCatalogView } from "@monorepo/catalog/src/store/modules/archiveCatalogView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, mapMutations, Module } from "vuex";
import { breadcrumbs } from "@monorepo/catalog/src/views/ArchiveView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/archiveView/viewTitle";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import useGetTableLibs from "@monorepo/catalog/src/views/ArchiveView/composables/useGetTableLibs";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import ConcatFooterButton from "@monorepo/uikit/src/components/tableViews/ConcatFooterButton.vue";
import RestoreFooterButton from "@monorepo/uikit/src/components/tableViews/RestoreFooterButton.vue";
import DeleteFooterButton from "@monorepo/uikit/src/components/tableViews/DeleteFooterButton.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { convertApiItemToUi } from "@monorepo/catalog/src/views/ArchiveView/utils/convertApiItemToUi";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { fields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/archiveView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/catalog/src/views/ArchiveView/utils/defaultCurrentFilters";
import useFooterActions from "@monorepo/utils/src/composables/useFooterActions";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

const SECTION_NAME = "archiveCatalogView";
const pluralFormTitles = ["Архив", "Архива", "Архивов"];

export default defineComponent({
  name: "ArchiveView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    Table,
    AddElement,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    ArchiveInfoModal,
    Footer,
    ToggleDocumentView,
    ExportFooterButton,
    ConcatFooterButton,
    RestoreFooterButton,
    DeleteFooterButton,
    DocumentTree: () =>
      import(
        /* webpackChunkName: "archiveDocumentTree" */
        "@monorepo/catalog/src/views/ArchiveView/components/DocumentTree.vue"
      ),
  },
  data() {
    return {
      breadcrumbs,
      ModalType,
      viewTitle,
      viewUniqKey,
      isOnceSelected: false,
      educationIds,
      pluralFormTitles,
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      ViewMode,
      autocompleteFiltersKeys: [fields.ARCHIVE_CODE, fields.ARCHIVE_NAME, fields.ARCHIVE_SHORT_NAME, fields.ARCHIVE_ID],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "viewMode",
      "isOpenFilters",
      "cells",
      "isSelectAll",
      "infiniteId",
      "data",
      "totalLength",
      "selectedIds",
      "isTableLoading",
    ]),
    currentDocumentView(): ViewMode {
      return this.viewMode?.typeDisplay || ViewMode.TABLE;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_EXPORT);
    },
    isShowConcatBtn(this: { isSelectAll: boolean; selectedNotDeletedLength: number; user: { authorities: { authority: authorities }[] } }): boolean {
      const isAuthority = [authorities.ARCHIVE_CONCAT, authorities.ARCHIVE_MODIFY].every((authority) => isAuthorityExist(this.user, authority));
      return !this.isSelectAll && this.selectedNotDeletedLength > 1 && isAuthority;
    },
    isCanDelete(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_DELETE);
    },
    isCanConcat(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_MODIFY);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_MODIFY);
    },
    isShowFooter(): boolean {
      return (
        (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) &&
        (this.isShowExport || this.isShowDeleteBtn || this.isShowRestoreBtn)
      );
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapMutations(SECTION_NAME, ["refreshData", "setSelectedIds"]),
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "addOpenedId",
      "getExportData",
      "setIsOpenFilters",
      "refreshScroll",
      "deleteElement",
      "restoreEntity",
    ]),
    openModalIfNeed(this: {
      isOnceSelected: boolean;
      $route: Route;
      data: IArchiveElement[];
      addOpenedId: (id: string) => void;
      showModal: boolean;
    }) {
      if (this.isOnceSelected) {
        return;
      }

      this.isOnceSelected = true;

      if (this.$route.query?.isOpenModal && this.data.length) {
        if (this.$route.query?.id) {
          this.addOpenedId(this.$route.query?.id as string);
          this.showModal = true;
          return;
        }
        this.addOpenedId(this.data[0].id);
        this.showModal = true;
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
        this.showModal = true;
      }
    },
    openConcatModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ConcatArchiveModal"));
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event.id) {
            this.addOpenedId(event.id);
            this.openCardModal(event.isDeleted as boolean);
          }
          break;
        case fields.ARCHIVE_FUND: {
          this.moveByAuthorities(header, "/dictionaries/fund", {
            name: event as unknown as string,
            isOpenModal: "1",
          });
          break;
        }
        default:
          break;
      }
    },
    openCardModal(this: { toggleModal: (value: boolean, type?: ModalType) => void; isCanEdit: boolean }, isDeleted: boolean) {
      if (isDeleted) {
        return this.toggleModal(true, ModalType.READ);
      }
      this.toggleModal(true, this.isCanEdit ? ModalType.EDIT : ModalType.READ); // NOT SIMPLIFIED!
    },
    changeType(type: ModalType) {
      this.type = type;
      if (type === ModalType.NEW) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    toggleModal(value: boolean, type?: ModalType) {
      this.showModal = value;
      this.type = type || ModalType.NEW;
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const filtersLoaded = ref(false);
    const showModal = ref(false);
    const type = ref(ModalType.EDIT);
    const moveByAuthorities = useMoveByAuthorities(root);

    const openModal = () => {
      if (root.$store.getters[`${SECTION_NAME}/openedId`]) {
        showModal.value = true;
        const isCanEdit = !!root.$store.getters["auth/user"]?.authorities?.find((element: { authority: string }) => {
          return element.authority === authorities.ARCHIVE_MODIFY;
        });
        type.value = isCanEdit ? ModalType.EDIT : ModalType.READ;
      }
    };

    const { isShowDeleteBtn, isShowRestoreBtn, openDeleteModal, openRestoreModal } = useFooterActions(
      root,
      SECTION_NAME,
      pluralFormTitles,
      authorities.ARCHIVE_DELETE
    );

    useInitTableStoreModule(root, SECTION_NAME, ArchiveCatalogView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME, openModal);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useSetStartFilters(root, SECTION_NAME, [], ["name", "id", "shortName"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
      openModal();
    });
    useGetTableLibs(root);
    return {
      filtersLoaded,
      showModal,
      type,
      sectionName,
      moveByAuthorities,
      isShowDeleteBtn,
      isShowRestoreBtn,
      openDeleteModal,
      openRestoreModal,
    };
  },
});
