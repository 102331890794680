import { render, staticRenderFns } from "./FundsTable.vue?vue&type=template&id=ee36a78e&scoped=true&"
import script from "./FundsTable.vue?vue&type=script&lang=ts&"
export * from "./FundsTable.vue?vue&type=script&lang=ts&"
import style0 from "./FundsTable.vue?vue&type=style&index=0&id=ee36a78e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee36a78e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VSimpleTable,VTooltip})
