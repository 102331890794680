import { fields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.ARCHIVE_CODE]: {},
  [fields.ARCHIVE_NAME]: {},
  [fields.ARCHIVE_ID]: {},
  [fields.ARCHIVE_SHORT_NAME]: {},
  [fields.ARCHIVE_TYPE]: [],
  [fields.ARCHIVE_IS_DELETED]: [],
});
