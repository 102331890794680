














































import { defineComponent } from "@vue/composition-api";
import moment from "moment";
import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";

const tableHeaders = [
  {
    width: 140,
    defaultWidth: 140,
    value: "id",
    text: "Идентификатор фонда",
    tooltip: "Идентификатор фонда в ЦХЭД",
  },
  {
    width: 100,
    defaultWidth: 100,
    value: "code",
    text: "Код фонда",
    tooltip: "Код фонда (Идентификатор фонда при взаимодействии с ОИК)",
  },
  { width: 250, defaultWidth: 250, value: "name", text: "Наименование фонда", tooltip: "Наименование фонда" },
  {
    width: 200,
    defaultWidth: 200,
    value: "shortName",
    text: "Краткое наименование фонда",
    tooltip: "Краткое наименование фонда",
  },
  { width: 250, defaultWidth: 250, value: "number", text: "№ фонда", tooltip: "№ фонда" },
  { width: 116, defaultWidth: 160, value: "dateFirstArrival", text: "Начальная дата фонда", tooltip: "Начальная дата фонда" },
  { width: 116, defaultWidth: 160, value: "dateLastArrival", text: "Конечная дата фонда", tooltip: "Конечная дата фонда" },
  { width: 116, defaultWidth: 160, value: "status", text: "Состояние", tooltip: "Состояние может принимать значения: Активный, Удалённый" },
];

export default defineComponent({
  name: "FundsTable",
  props: {
    funds: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      tableHeaders,
    };
  },
  computed: {
    resultData(): Partial<IFundElement>[] {
      return (
        (this.funds as IFundElement[]).map((fund) => {
          return {
            ...fund,
            dateFirstArrival: fund.dateFirstArrival ? moment(fund.dateFirstArrival).format("DD-MM-YYYY") : "",
            dateLastArrival: fund.dateLastArrival ? moment(fund.dateLastArrival).format("DD-MM-YYYY") : "",
            status: fund.isDeleted ? "Удалённый" : "Активный",
          };
        }) || []
      );
    },
  },
  methods: {
    onScrollCb(e: Event) {
      const element = document.getElementById("doc-table");
      if (element) {
        element.scrollLeft = (e.target as HTMLElement)?.scrollLeft;
      }
    },
  },
});
