import { IArchiveCardElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveCardElement";

export const convertSaveCardObject = (resultData: Partial<IArchiveCardElement>): Partial<IArchiveCardElement> => {
  return {
    id: resultData.id || "",
    code: resultData.code || "",
    name: resultData.name || "",
    shortName: resultData.shortName || "",
    address: resultData.address || "",
    description: resultData.description || "",
    isDeleted: !!resultData.isDeleted,
  };
};
