import axios from "axios";
import { Module } from "vuex";
import { IRootState } from "@monorepo/catalog/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { IArchiveCardElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveCardElement";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { paramsSerializer } from "@monorepo/utils/src/api/paramsSerializer";
import { elementStatuses } from "@monorepo/utils/src/variables/elementStatuses";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import {
  convertFiltersCustomToApi as convertFiltersArchiveToApi,
  convertFiltersCustomToApi,
} from "@monorepo/catalog/src/views/ArchiveView/utils/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { fields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { IOikElement } from "@monorepo/catalog/src/views/OikView/types/oikElement";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  data: IArchiveElement[];
  sectionCells: ISection;
  searchTemplates: ISearchTemplate[];
  infiniteId: string;
  cells: string[];
  isOpenFilters: boolean;
  libraries: {
    oiks: IOikElement[];
    funds: IFundElement[];
  };
  section: Sections;
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  viewMode: {
    id?: number;
    typeDisplay: ViewMode;
  };
}

const archiveFilters = (): ITableFiltersObject => ({
  sort: {
    shortName: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [elementStatuses[0].value],
  },
  initMessagesLength: 5000,
});

export interface IArchiveHierarchyElement extends IArchiveCardElement {
  funds: IFundElement[];
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    [fields.ARCHIVE_CODE]: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [elementStatuses[0].value],
  },
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_CATALOG_ARCHIVE_CSV;
    case "XLSX":
      return QUERY_PATH.GET_CATALOG_ARCHIVE_XLS;
    default:
      return QUERY_PATH.GET_CATALOG_ARCHIVE_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    sectionCells: {} as ISection,
    searchTemplates: [],
    totalLength: 0,
    cells: [],
    isOpenFilters: false,
    data: [],
    libraries: {
      oiks: [],
      funds: [],
    },
    section: Sections.ARCHIVE,
    isTableLoading: false,
    isLoadingToggleFilters: false,
    viewMode: {
      typeDisplay: ViewMode.TABLE,
    },
  }),
  mutations: {
    ...baseMutations,
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    setData(state: ITableState, payload: IArchiveElement[]) {
      state.data = payload;
    },
    addFundLib(state: ITableState, payload: IFundElement[]) {
      state.libraries.funds =
        payload.map((archive) => {
          return { ...archive, label: archive.shortName };
        }) || [];
    },
    addData(
      state: ITableState,
      payload: {
        data: IArchiveElement[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    refreshInfiniteId(state: ITableState) {
      state.infiniteId = new Date().toString();
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addOiksLib(state: ITableState, payload: IOikElement[]) {
      state.libraries.oiks = state.libraries.oiks.concat(payload);
    },
  },
  actions: {
    ...baseActions,
    async restoreEntity(info, id: string | number) {
      try {
        await axios.put(getFullPath(QUERY_PATH.GET_CATALOG_ARCHIVE_LIST), { id, isDeleted: false });
        return true;
      } catch (e) {
        return false;
      }
    },
    async getAutocompleteByCode(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_ARCHIVE_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.name,
        }));
      }
      return [];
    },
    async getAutocompleteById(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-id/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { id: string }) => ({
          ...item,
          titleValue: item.id,
        }));
      }
      return [];
    },
    async getAutocompleteByShortName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-short-name/like`,
        { keyword: params },
        false
      );
      if (data !== null) {
        return data.map((item: { shortName: string }) => ({
          ...item,
          titleValue: item.shortName,
        }));
      }
      return [];
    },
    async getFundList({ commit }) {
      const params = convertFiltersToApi(archiveFilters(), 0, convertFiltersArchiveToApi);
      const resultParams = {
        ...(params || {}),
        short: true,
      };
      const { data } = await getQuery<IFundElement[]>(QUERY_PATH.GET_CATALOG_FUND_LIST, resultParams);
      if (data !== null) {
        commit("addFundLib", data);
      }
      return [];
    },
    async deleteElement({ state }, params: Record<string, unknown>) {
      try {
        const { filters } = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
        await axios.delete(
          getFullPath(params?.deleteAll ? `${QUERY_PATH.GET_CATALOG_ARCHIVE_LIST}/by-filter` : QUERY_PATH.GET_CATALOG_ARCHIVE_LIST),
          {
            params: params?.deleteAll ? { filters } : params,
            paramsSerializer,
          }
        );
        return true;
      } catch (e) {
        return false;
      }
    },
    async saveCard(info, data: Record<string, unknown>): Promise<boolean | string> {
      try {
        const response = await axios[!data.id ? "post" : "put"](getFullPath(QUERY_PATH.GET_CATALOG_ARCHIVE_LIST), data).catch(function (error) {
          const messagesArr = Object.values(error?.response?.data?.data || {});
          showNotification(messagesArr.length ? messagesArr.join(". ") : error?.response?.data?.message);
          return Promise.reject(error);
        });
        return response.data.data.id;
      } catch (e) {
        return false;
      }
    },
    async getCardElement(info, id: string) {
      const { data } = await getQuery<IArchiveCardElement>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_LIST}/${id}`);
      return data;
    },
    async getArchiveHierarchy({ commit, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const { data } = await getQuery<IArchiveHierarchyElement[]>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_HIERARCHY}`).finally(() => {
        commit("setTableLoading", false);
      });
      return data;
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IArchiveElement[]>(QUERY_PATH.GET_CATALOG_ARCHIVE_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), { root: true });
    },
    async saveFundToNewArchive(info, data: Record<string, unknown>): Promise<boolean> {
      try {
        await axios.put(getFullPath(QUERY_PATH.GET_CATALOG_FUND_LIST), data).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async getOiksList({ commit, state, dispatch }) {
      const params = convertFiltersToApi(defaultFilters(), state.libraries.oiks.length, convertFiltersCustomToApi);
      const { data, total } = await getQuery<IOikElement[]>(QUERY_PATH.GET_CATALOG_OIK_LIST, params);
      if (data !== null) {
        commit("addOiksLib", data);
      }
      if (state.libraries.oiks.length < total) {
        dispatch("getOiksList");
      }
      return [];
    },
  },
  getters: {
    ...baseGetters,
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    oiksList(state: ITableState) {
      return state.libraries.oiks;
    },
    fundsLib(state: ITableState) {
      return state.libraries.funds;
    },
  },
};
