import { fields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";

export const filtersElements = [
  {
    value: fields.ARCHIVE_CODE,
    title: "Код архива",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getAutocompleteByCode",
    width: 268,
  },
  {
    value: fields.ARCHIVE_NAME,
    title: "Наименование архива",
    type: "autocomplete",
    showSelectedKey: "name",
    autocompleteActionName: "getAutocompleteByCode",
    width: 268,
  },
  {
    value: fields.ARCHIVE_SHORT_NAME,
    title: "Краткое наименование архива",
    type: "autocomplete",
    showSelectedKey: "shortName",
    autocompleteActionName: "getAutocompleteByShortName",
    width: 268,
  },
  {
    value: fields.ARCHIVE_ID,
    title: "Идентификатор архива",
    type: "autocomplete",
    showSelectedKey: "id",
    autocompleteActionName: "getAutocompleteById",
    width: 268,
  },
  {
    value: fields.ARCHIVE_STATUS,
    title: "Состояние",
    type: "selectStatus",
    width: 340,
  },
];
